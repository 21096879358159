// src/components/Profile.js

import React from "react";
import { useAuth, userDisplayName } from "../contexts/AuthContext";
import MyLikes from "./MyLikes";

const Profile = () => {
  const { currentUser } = useAuth();
  const displayName = userDisplayName(currentUser);

  return (
    <div>
      <h2>{displayName}</h2>
      <MyLikes />
    </div>
  );
};

export default Profile;
