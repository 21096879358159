import React, { useEffect, useState } from "react";
import "./NeighborhoodGuides.css";
import { Link } from "react-router-dom";
import sanityClient from "../sanityClient.js";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return source ? builder.image(source) : null;
}

const NeighborhoodGuides = () => {
  const [neighborhoods, setNeighborhoods] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "neighborhood"]{
          title,
          slug,
          tileTitle,
          tileImage{
            asset->{
              _id,
              url
            }
          },
        }`
      )
      .then((data) => setNeighborhoods(data))
      .catch(console.error);
  }, []);

  return (
    <div className="neighborhood-guides">
      <h2>Find Your Neighborhood</h2>
      <div className="neighborhood-grid">
        {neighborhoods.map((neighborhood, index) => (
          <Link
            to={`/neighborhood/${neighborhood.slug.current}`}
            key={index}
            style={{ textDecoration: "none", color: "black" }}
          >
            <div className="neighborhood-card">
              <img
                src={urlFor(neighborhood.tileImage.asset)?.url()}
                alt={neighborhood.tileTitle}
              />
              <h3>{neighborhood.tileTitle}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NeighborhoodGuides;
