import React, { useState, useEffect } from "react";
import sanityClient from "../sanityClient";
import { Carousel } from "react-responsive-carousel";
import imageUrlBuilder from "@sanity/image-url";

import "./CommunityPage.css";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function CommunityPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    sanityClient.fetch('*[_type == "communityPage"]').then((data) => {
      setData(data[0]);
    });
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div>
      {/* Carousel remains unchanged */}
      <Carousel>
        {data.carousel.map((item) => (
          <div key={item._key}>
            <img src={urlFor(item.image).url()} alt="Carousel" />
          </div>
        ))}
      </Carousel>
      ...
      <div className="tiles">
        {data.tiles.map((tile) => (
          <div key={tile._key} className="tile">
            <img src={urlFor(tile.image).url()} alt={tile.header} />
            <h2>{tile.subheader}</h2>
            <h1>{tile.header}</h1>
            <a href={tile.link}>Link</a>
          </div>
        ))}
      </div>
      {/* Icon Section remains unchanged */}
      <div className="icons-section">
        <h1>{data.iconHeader}</h1>
        <div className="icons">
          {data.icons.map((icon) => (
            <img
              key={icon._key}
              className="icon"
              src={urlFor(icon.icon).url()}
              alt="Icon"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CommunityPage;
