// src/components/ListingsCard.js

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
import LikesContext from "../context/LikeContext";

const ListingsCard = ({ property }) => {
  const { id, address, price, bedrooms, bathrooms, sqft, image_url } = property;
  const { likes, addLike, removeLike } = useContext(LikesContext);
  const liked = likes.some((like) => like.id === property.id);

  const handleLike = () => {
    if (liked) {
      removeLike(property.id);
    } else {
      addLike(property);
    }
  };
  return (
    <div className="card">
      <img src={image_url} className="card-img-top" alt={address} />
      <div className="card-body">
        <h5 className="card-title">{address}</h5>
        <p className="card-text">
          {bedrooms} Beds | {bathrooms} Baths | {sqft} Sq. Ft.
        </p>
        <p className="card-text">
          <strong>
            {price ? `$${price.toLocaleString()}` : "Price not available"}
          </strong>
        </p>
        <Link to={`/property/${id}`} className="btn btn-primary">
          View Details
        </Link>
        <button
          className={`btn ${liked ? "btn-danger" : "btn-outline-danger"} mt-2`}
          onClick={handleLike}
        >
          <FiHeart />
          {liked ? " Liked" : " Like"}
        </button>
      </div>
    </div>
  );
};

export default ListingsCard;
