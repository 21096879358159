import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import "./QuickSearch.css";
import IFrameComponent from "./IFrameComponent";

const QuickSearch = ({ onSearch }) => {
  const navigate = useNavigate();

  const defaultSearchParams = {
    location: "",
    propertyType: "",
    minPrice: "",
    maxPrice: "",
    bedrooms: "",
    bathrooms: "",
    squareFeet: "",
    lotSize: "",
  };

  const [searchParams, setSearchParams] = useState(() => {
    const storedParams = localStorage.getItem("searchParams");
    return storedParams ? JSON.parse(storedParams) : defaultSearchParams;
  });

  useEffect(() => {
    localStorage.setItem("searchParams", JSON.stringify(searchParams));
  }, [searchParams]);

  const handleChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchParams);
    navigate("/properties", { state: { searchParams } });
  };

  const resetFilters = () => {
    setSearchParams(defaultSearchParams);
    localStorage.removeItem("searchParams");
  };

  return (
    <motion.div
      initial={{ x: -800 }}
      animate={{ x: 0 }}
      transition={{ ease: "easeOut", duration: 1 }}
      className="quicksearch-container mx-auto"
      style={{
        fontFamily: "Merriweather, serif",
        maxWidth: "1200px",
        padding: "20px",
      }}
    >
      {/* <Helmet>
        <title>Quick Property Search</title>
        <meta
          name="description"
          content="Quickly search for properties based on your criteria."
        />
      </Helmet> */}

      <h1 className="d-flex flex-column align-items-center">Quick Search</h1>
      <Container className="mt-4 mx-auto">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.Location"
            >
              <Form.Control
                placeholder="City, Neighborhood or Zip"
                name="location"
                value={searchParams.location}
                onChange={handleChange}
                className="mb-3"
              />
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.PropertyType"
            >
              <Form.Select
                name="propertyType"
                value={searchParams.propertyType}
                onChange={handleChange}
                className="mb-3"
              >
                <option value="">Property Type</option>
                <option value="House">House</option>
                <option value="Apartment">Apartment</option>
                <option value="Condo">Condo</option>
                <option value="Townhouse">Townhouse</option>
                <option value="Lot/Land">Lot/Land</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.MinPrice"
            >
              <Form.Select
                name="minPrice"
                value={searchParams.minPrice}
                onChange={handleChange}
                className="mb-3"
              >
                <option value="">Min Price</option>
                <option value="25000">$25,000</option>
                <option value="50000">$50,000</option>
                <option value="100000">$100,000</option>
                <option value="200000">$200,000</option>
                <option value="300000">$300,000</option>
                <option value="400000">$400,000</option>
                <option value="500000">$500,000</option>
                <option value="600000">$600,000</option>
                <option value="700000">$700,000</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.MaxPrice"
            >
              <Form.Select
                name="maxPrice"
                value={searchParams.maxPrice}
                onChange={handleChange}
                className="mb-3"
              >
                <option value="">Max Price</option>
                <option value="400000">$400,000</option>
                <option value="500000">$500,000</option>
                <option value="600000">$600,000</option>
                <option value="700000">$700,000</option>
                <option value="800000">$800,000</option>
                <option value="900000">$900,000</option>
                <option value="1000000">$1,000,000</option>
                <option value="1100000">$1,100,000</option>
                <option value="1200000">$1,200,000</option>
                <option value="1300000">$1,300,000</option>
                <option value="1400000">$1,400,000</option>
                <option value="1500000">$1,500,000</option>
                <option value="1600000">$1,600,000</option>
                <option value="1700000">$1,700,000</option>
                <option value="1800000">$1,800,000</option>
                <option value="1900000">$1,900,000</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.Bedrooms"
            >
              <Form.Select
                name="bedrooms"
                value={searchParams.bedrooms}
                onChange={handleChange}
                className="mb-3"
              >
                <option value="">Beds</option>
                <option value="0">0+</option>
                <option value="1">1+</option>
                <option value="2">2+</option>
                <option value="3">3+</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.Bathrooms"
            >
              <Form.Select
                name="bathrooms"
                value={searchParams.bathrooms}
                onChange={handleChange}
                className="mb-3"
              >
                <option value="">Baths</option>
                <option value="0">0+</option>
                <option value="1">1+</option>
                <option value="2">2+</option>
                <option value="3">3+</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.SquareFeet"
            >
              <Form.Control
                type="number"
                placeholder="Minimum Square Feet"
                name="squareFeet"
                value={searchParams.squareFeet}
                onChange={handleChange}
                className="mb-3"
              />
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              sm={6}
              md={4}
              controlId="quickSearchForm.LotSize"
            >
              <Form.Control
                type="number"
                placeholder="Minimum Lot Size (sq ft)"
                name="lotSize"
                value={searchParams.lotSize}
                onChange={handleChange}
                className="mb-3"
              />
            </Form.Group>

            <Form.Group
              as={Col}
              xs={12}
              className="d-flex justify-content-center"
              controlId="quickSearchForm.Submit"
            >
              <Button
                variant="primary"
                type="submit"
                className="mt-3" // Ensure the button has a margin top
                style={{
                  backgroundColor: "black",
                  color: "#fff",
                  border: "white",
                  width: "80%", // or any percentage or fixed width depending on your design
                }}
              >
                Search
              </Button>
            </Form.Group>
          </Row>
        </Form>

        <div className="d-flex justify-content-center">
          <Button
            variant="dark"
            className="w-50 mt-3"
            style={{
              backgroundColor: "#3D84C5",
              color: "#fff",
              border: "none",
            }}
            onClick={resetFilters}
            aria-label="Reset filters"
          >
            Reset Filters
          </Button>
        </div>
      </Container>
    </motion.div>
  );
};

export default QuickSearch;
