import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";
import "./Sell.css";

const Sell = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const [status, setStatus] = useState(""); // Tracks submission status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_3o3qp4q", // Replace with your EmailJS service ID
        "template_vt0o0gg", // Replace with your EmailJS template ID
        formData,
        "mYjN8V40D5arHk_Tq" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          setStatus("success");
        },
        (error) => {
          console.error("Error sending email:", error.text);
          setStatus("error");
        }
      );
    setFormData({
      name: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    });
  };

  return (
    <>
      <Helmet>
        <title>Sell Your Property in Salida, Buena Vista, and Surrounding Areas</title>
        <meta
          name="description"
          content="Sell your home in Salida, Buena Vista, Poncha Springs, Westcliffe, or Howard, Colorado. Expert real estate services to get you the best deal."
        />
        <meta name="keywords" content="Sell in Salida, Realtor in Buena Vista, Real Estate in Poncha Springs, Howard, Westcliffe, Colorado" />
      </Helmet>

      <div className="sell-container">
        <div className="sell-header">
          <h1>Sell Your Property in Salida and Beyond</h1>
          <p>
            Ready to sell? Let us help you navigate the market in Salida, Buena Vista, Poncha Springs, Westcliffe, and Howard.
          </p>
        </div>
        <div className="sell-content">
          {status === "success" && (
            <div className="success-message">
              <h2>Thank You!</h2>
              <p>Your information has been submitted. We'll contact you shortly!</p>
            </div>
          )}
          {status === "error" && (
            <div className="error-message">
              <h2>Oops!</h2>
              <p>Something went wrong. Please try again later.</p>
            </div>
          )}
          {status === "" && (
            <form className="sell-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Property Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Property Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Additional Details</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Tell us about your property"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default Sell;
