import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import { Link } from "react-router-dom";
import "./FeaturedListings.css";
import Skeleton from "./Skeleton";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function formatPrice(price) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price);
}

export default function FeaturedListings() {
  const [listingOrder, setListingOrder] = useState([]);
  const [featuredListingsData, setFeaturedListingsData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "featuredListingOrder"][0].list[]->_id`)
      .then((order) => {
        setListingOrder(order);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (listingOrder.length > 0) {
      sanityClient
        .fetch(
          `*[_type == "featuredListing" && status == "active"]{
            _id,
            title,
            images,
            slug,
            address,
            price,
            bedroom,
            bathroom,
            sqft
          }`
        )
        .then((data) => {
          // Map the order of the IDs in listingOrder to the data
          const orderedData = listingOrder.map((id) =>
            data.find((listing) => listing?._id === id)
          ).filter(listing => listing !== undefined);
          setFeaturedListingsData(orderedData);
        })
        .catch(console.error);
    }
  }, [listingOrder]);

  if (!featuredListingsData) {
    return <Skeleton title image text />;
  }

  return (
    <div className="featured-listings">
      <h2 className="featured-h2">Featured Properties | Houses For Sale</h2>
      <div className="listings-grid">
        {featuredListingsData.map((listing, index) => (
          <Link
            to={"/featured-listing/" + listing.slug.current}
            key={index}
            className="listing-card"
          >
            <div className="image-container">
              {listing.images && listing.images[0] && (
                <img
                  src={urlFor(listing.images[0]).url()}
                  alt={listing.title}
                />
              )}

              <div className="for-sale">FOR SALE</div>
            </div>
            <h3>{listing.title}</h3>
            <p className="address">{listing.address}</p>
            <p className="bed-bath">
              {listing.bedroom !== 0 && listing.bathroom !== 0
                ? `${new Intl.NumberFormat().format(
                    listing.bedroom
                  )} BR | ${new Intl.NumberFormat().format(
                    listing.bathroom
                  )} BA`
                : `${new Intl.NumberFormat().format(listing.sqft)} SQFT`}
            </p>
            <p className="price">{formatPrice(listing.price)}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}
