import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient.js";
import imageUrlBuilder from "@sanity/image-url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import "./About.css";
import ContactForm from "./ContactForm.js";
import Skeleton from "./Skeleton.js";
import TrendsComponent from "./TrendsComponent.js";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const About = () => {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"]{
          headerTitle,
          headerSubtitle,
          backgroundImage{
            asset->{
              _id,
              url
            },
          },
          profileTitle,
          profileDescription,
          profilePicture{
            asset->{
              _id,
              url
            },
          },
          contactEmail,
          contactNumber
        }`
      )
      .then((data) => setAboutData(data[0]))
      .catch(console.error);
  }, []);

  if (!aboutData) return <Skeleton title image text />;

  return (
    <main className="about-container">
      <Helmet>
        <title>About | Beatrice Price</title>
        <meta name="description" content="About page description for SEO" />
      </Helmet>
      <div className="background-container">
        <img
          src={urlFor(aboutData.backgroundImage.asset.url)}
          alt="Background"
          className="background-image"
        />
        <div className="header-container">
          <h1 className="header-title">{aboutData.headerTitle}</h1>
          <h2 className="header-subtitle">{aboutData.headerSubtitle}</h2>
        </div>
      </div>
      <div className="profile-container">
        <img
          src={urlFor(aboutData.profilePicture.asset.url)}
          alt="Profile"
          className="profile-picture"
        />
        <div className="profile-text">
          <h3 className="profile-title">{aboutData.profileTitle}</h3>
          <p className="profile-description">{aboutData.profileDescription}</p>
        </div>
      </div>
      <div className="contact-container">
        <p className="contact-email">
          <FontAwesomeIcon icon={faEnvelope} /> {aboutData.contactEmail}
        </p>
        <p className="contact-number">
          <FontAwesomeIcon icon={faPhone} /> {aboutData.contactNumber}
        </p>
      </div>
      <ContactForm />
      {/* <TrendsComponent /> */}
    </main>
  );
};

export default About;
