import React, { useState, useEffect } from "react";
import Skeleton from "./Skeleton";
import { Helmet } from "react-helmet";
import "./IFrameComponent.css";

const IFrameComponent = ({ initialSrc, title }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Automatically set the src when the component mounts
    setIsLoading(true);
  }, []);

  return (
    <div className="iframe-container">
      <Helmet>
        <title>Listings in Salida, Buena Vista, and Colorado</title>
        <meta
          name="description"
          content="Explore active and pending residential property listings in Salida, Buena Vista, and nearby Colorado areas. Find your dream home today!"
        />
        <meta
          name="keywords"
          content="Salida real estate, Buena Vista properties, Colorado homes, residential listings, active homes, pending sales"
        />
      </Helmet>
      {isLoading && <Skeleton title={false} image={true} text={false} />}
      <iframe
        src="https://firstcolorado.com/home-search/listings?sortBy=LP_LISTING_ON_MARKET_TIMESTAMP&sortDirection=DESC&propertyType=[%22RESIDENTIAL%22]&listingStatus=[%22ACTIVE%22,%22ACTIVE_UNDER_CONTRACT%22,%22PENDING%22]&center={%22lat%22:38.52494535010743,%22lng%22:-106.01342300000002}&zoom=11&boundary=[[[38.657776578987765,-106.31554702343752],[38.657776578987765,-105.71129897656252],[38.391868495892375,-105.71129897656252],[38.391868495892375,-106.31554702343752],[38.657776578987765,-106.31554702343752]]]&omnibox=&keyword=&page=1&limit=12&isLease=false&cityName=&stateName="
        title={title}
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
          alert("Failed to load content");
        }}
        style={{ display: isLoading ? "none" : "block" }}
        width="100%"
        height="600px"
      />
    </div>
  );
};

export default IFrameComponent;
