// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_8-G4Z3xQLxMEbMemn7xlP1kSSVGBVXQ",
  authDomain: "recolorado-41968.firebaseapp.com",
  projectId: "recolorado-41968",
  storageBucket: "recolorado-41968.appspot.com",
  messagingSenderId: "892714155945",
  appId: "1:892714155945:web:e67e82c62b84992d398eb8",
  measurementId: "G-R5HB32N6HD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
