// Home.js
import React from "react";
import Hero from "./components/Hero";
import QuickSearch from "./components/QuickSearch";
import ListingsCard from "./components/ListingsCard";
import Testimonials from "./components/Testimonials";
import NeighborhoodGuides from "./components/NeighborhoodGuides";
import FeaturedListings from "./components/FeaturedListings";
import AgentCard from "./components/AgentCard";
import SoldListings from "./components/SoldListings";
import "./Home.css";

const Home = ({ onSearch, properties }) => {
  return (
    <>
      <Hero />
      <div className="container">
        <FeaturedListings />
        {/* <QuickSearch onSearch={onSearch} /> */}
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {properties.map((property) => (
            <div className="col" key={property.id}>
              <ListingsCard property={property} />
            </div>
          ))}
        </div>

        <AgentCard />
        <SoldListings />
        <Testimonials />
        <NeighborhoodGuides />
      </div>
    </>
  );
};

export default Home;
