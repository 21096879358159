// Properties.js
import React, { useState, useEffect } from "react";
import ListingsCard from "../components/ListingsCard";

const Properties = ({ properties }) => {
  //   const location = useLocation();
  //   const searchParams = new URLSearchParams(location.search);

  //   const [filteredProperties, setFilteredProperties] = useState([]);

  //   useEffect(() => {
  //     setFilteredProperties(
  //       properties.filter((property) => {
  //         // Filter properties based on search parameters. This is just a simple example.
  //         return property.address
  //           .toLowerCase()
  //           .includes(searchParams.get("location").toLowerCase());
  //       })
  //     );
  //   }, [properties, searchParams]);

  return (
    <div className="row row-cols-1 row-cols-md-3 g-4">
      {/* {filteredProperties.map((property) => (
        <div className="col" key={property.id}>
          <ListingsCard property={property} />
        </div>
      ))} */}
    </div>
  );
};

export default Properties;
