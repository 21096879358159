// src/context/LikesProvider.js

import React, { useState } from "react";
import LikesContext from "./LikeContext";

const LikesProvider = ({ children }) => {
  const [likes, setLikes] = useState([]);

  const addLike = (property) => {
    setLikes([...likes, property]);
  };

  const removeLike = (propertyId) => {
    setLikes(likes.filter((like) => like.id !== propertyId));
  };

  return (
    <LikesContext.Provider value={{ likes, addLike, removeLike }}>
      {children}
    </LikesContext.Provider>
  );
};

export default LikesProvider;
