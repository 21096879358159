import React, { useState } from "react";
import "./MortgageCalculator.css";

const MortgageCalculator = () => {
  const [loanAmount, setLoanAmount] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [propertyTax, setPropertyTax] = useState("");
  const [homeInsurance, setHomeInsurance] = useState("");
  const [pmi, setPmi] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState("");

  const calculatePayment = (e) => {
    e.preventDefault();
    const monthlyRate = interestRate / 100 / 12;
    const n = loanTerm * 12;
    const principalAndInterest =
      (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -n));
    const monthlyPropertyTax = propertyTax / 12;
    const monthlyHomeInsurance = homeInsurance / 12;
    const monthlyPmi = (loanAmount * pmi) / 100 / 12;
    const payment =
      principalAndInterest +
      monthlyPropertyTax +
      monthlyHomeInsurance +
      monthlyPmi;
    setMonthlyPayment(payment.toFixed(2));
  };

  return (
    <div className="calculator-container">
      <form onSubmit={calculatePayment} className="calculator-form">
        <h1>Mortgage Calculator</h1>

        <label title="The amount of the loan">Loan Amount ($)</label>
        <input
          type="number"
          placeholder="Loan Amount"
          value={loanAmount}
          onChange={(e) => setLoanAmount(e.target.value)}
          required
        />

        <label title="The number of years for the loan">Loan Term (years)</label>
        <input
          type="number"
          placeholder="Loan Term in Years"
          value={loanTerm}
          onChange={(e) => setLoanTerm(e.target.value)}
          required
        />

        <label title="The interest rate of the loan">Interest Rate (%)</label>
        <input
          type="number"
          placeholder="Interest Rate"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          required
        />

        {/* <label title="The annual property taxes">Annual Property Taxes ($)</label>
        <input
          type="number"
          placeholder="Annual Property Taxes"
          value={propertyTax}
          onChange={(e) => setPropertyTax(e.target.value)}
          required
        />

        <label title="The annual home insurance premium">Annual Home Insurance ($)</label>
        <input
          type="number"
          placeholder="Annual Home Insurance"
          value={homeInsurance}
          onChange={(e) => setHomeInsurance(e.target.value)}
          required
        /> */}

        {/* <label title="The annual PMI (Private Mortgage Insurance) rate">PMI (%)</label>
        <input
          type="number"
          placeholder="PMI"
          value={pmi}
          onChange={(e) => setPmi(e.target.value)}
        /> */}

        <button type="submit">Calculate</button>
      </form>

      {monthlyPayment && (
        <div className="results-container">
          <h2>Monthly Payment: ${monthlyPayment}</h2>
        </div>
      )}
    </div>
  );
};

export default MortgageCalculator;
