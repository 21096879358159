import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient.js";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Neighborhood.css";

const tabs = [
  { key: "resources", label: "Resources" },
  { key: "schools", label: "Schools" },
  { key: "events", label: "Events" },
  { key: "groceries", label: "Groceries" },
  { key: "restaurants", label: "Restaurants" },
  { key: "thingsToDo", label: "Things to Do" },
  { key: "meetPeople", label: "Meet People" },
];

const Neighborhood = () => {
  const [neighborhoodData, setNeighborhoodData] = useState(null);
  const [activeTab, setActiveTab] = useState("resources");
  const { neighborhoodSlug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "neighborhood" && slug.current == $slug]{
          title,
          resources,
          schools,
          events,
          groceries,
          restaurants,
          thingsToDo,
          meetPeople
        }`,
        { slug: neighborhoodSlug }
      )
      .then((data) => setNeighborhoodData(data[0]))
      .catch(console.error);
  }, [neighborhoodSlug]);

  if (!neighborhoodData) return <div className="loading">Loading...</div>;

  return (
    <div className="neighborhood-container">
      <Helmet>
        <title>{neighborhoodData.title}</title>
        <meta
          name="description"
          content={`Explore ${neighborhoodData.title}: A complete guide with resources, schools, events, groceries, restaurants, and activities.`}
        />
        <meta
          name="keywords"
          content={`${neighborhoodData.title}, resources, schools, events, groceries, restaurants, activities, neighborhood guide`}
        />
      </Helmet>

      <header className="neighborhood-header">
        <h1 className="neighborhood-title">{neighborhoodData.title}</h1>
        <p className="neighborhood-subtitle">
          Discover everything {neighborhoodData.title} has to offer.
        </p>
      </header>

      <nav className="tab-navigation">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            className={`tab-button ${
              activeTab === tab.key ? "active-tab" : ""
            }`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </nav>

      <main className="tab-content">
        {neighborhoodData[activeTab]?.length ? (
          neighborhoodData[activeTab].map((item, index) => (
            <div key={index} className="content-card">
              <h2 className="content-title">
                {item.resourceTitle ||
                  item.schoolTitle ||
                  item.eventTitle ||
                  item.groceryTitle ||
                  item.restaurantTitle ||
                  item.thingsTitle ||
                  item.meetTitle}
              </h2>
              <p className="content-description">
                {item.resourceDescription ||
                  item.schoolDescription ||
                  item.eventDescription ||
                  item.groceryDescription ||
                  item.restaurantDescription ||
                  item.thingsDescription ||
                  item.meetDescription}
              </p>
              <a
                href={
                  item.resourceUrl ||
                  item.schoolUrl ||
                  item.eventUrl ||
                  item.groceryUrl ||
                  item.restaurantUrl ||
                  item.thingsUrl ||
                  item.meetUrl
                }
                target="_blank"
                rel="noopener noreferrer"
                className="content-link"
              >
                Learn More
              </a>
            </div>
          ))
        ) : (
          <p className="no-content">No information available for this section.</p>
        )}
      </main>
    </div>
  );
};

export default Neighborhood;
