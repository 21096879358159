import React, { useState, useEffect } from "react";
import axios from "axios";

const WaterRightTransactions = () => {
  const [data, setData] = useState([]);
  const [county, setCounty] = useState(""); // to hold the selected county

  // fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://data.colorado.gov/resource/i55n-9sba.json?$$app_token=&$limit=5000&county=${county}`
      );
      setData(response.data);
    };

    fetchData();
  }, [county]); // re-run the effect when `county` changes

  const handleCountyChange = (event) => {
    setCounty(event.target.value);
  };

  return (
    <div>
      <select value={county} onChange={handleCountyChange}>
        <option value="">All counties</option>
        <option value="DENVER">Denver</option>
        <option value="BOULDER">Boulder</option>
        <option value="CHAFFEE">Chaffee</option>
        <option value="FREMONT">Fremont</option>
        <option value="SAGUACHE">Saguache</option>
      </select>

      <table>
        <thead>
          <tr>
            <th>WDID</th>
            <th>Water Right Name</th>
            <th>Structure Type</th>
            <th>Water Source</th>
            <th>adjudication_date</th>

            {/* Add additional fields as needed */}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.wdid}</td>
              <td>{item.water_right_name}</td>
              <td>{item.structure_type}</td>
              <td>{item.water_source}</td>
              <td>{item.adjudication_date}</td>
              <td>{item.decreed_uses}</td>
              {/* Add additional fields as needed */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WaterRightTransactions;
