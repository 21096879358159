import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./Sidebar.css";

const Sidebar = ({ items, toggle, setToggle }) => {
  const [activeMenu, setActiveMenu] = useState(null);

  const handleDropdownClick = (event, itemName) => {
    event.stopPropagation();

    if (activeMenu === itemName) {
      setActiveMenu(null);
    } else {
      setActiveMenu(itemName);
    }
  };

  const handleLinkClick = () => {
    setToggle(false);
  };

  const variants = {
    open: { x: 0 },
    closed: { x: "100%" },
  };

  const itemVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "100%" },
  };

  const bounceTransition = {
    type: "spring",
    stiffness: 300,
    damping: 20,
  };

  return (
    <motion.div
      className="sidebar-container"
      variants={variants}
      initial="closed"
      animate={toggle ? "open" : "closed"}
      transition={{ duration: 0.3 }}
    >
      <button className="sidebar-close-button" onClick={handleLinkClick}>
        Close
      </button>
      <div className="sidebar-items-cont">
        {items.map((item, index) => (
          <div key={index}>
            <motion.div
              key={index}
              className="sidebar-item-wrapper"
              // onClick={() => handleDropdownClick(item.name)}
              variants={itemVariants}
              initial="closed"
              animate={toggle ? "open" : "closed"}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Link className="sidebar-item" to={item.path} onClick={handleLinkClick}>
                {item.name}
                {item.dropdown && (
                  <button className="dropdown-arrow">&#9660;</button>
                )}
              </Link>
            </motion.div>

            {item.dropdown && activeMenu === item.name && (
              <div className="dropdown-menu">
                {item.dropdown.map((dropdownItem, index) => (
                  <motion.div
                    key={index}
                    className="dropdown-item"
                    onClick={handleLinkClick}
                    animate={{ scale: [1, 1.2, 0.9, 1] }}
                    transition={bounceTransition}
                  >
                    <Link to={dropdownItem.path}>{dropdownItem.name}</Link>
                  </motion.div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default Sidebar;
