import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import "./CommunityShowcase.css";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const CommunityShowcase = () => {
  const [communitiesData, setCommunitiesData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "community"]{
        title,
        description,
        image,
        amenities,
        location,
        featuredListings[]->{
          title,
          images,
          slug,
          address,
          price,
          bedroom,
          bathroom,
          sqft
        }
      }`
      )

      .then((data) => setCommunitiesData(data))
      .catch(console.error);
  }, []);

  return (
    <div className="community-showcase">
      <h2>Communities | Feel at Home</h2>
      <div className="community-grid">
        {communitiesData &&
          communitiesData.map((community, index) => (
            <div key={index} className="community-card">
              <div className="community-image-container">
                {community.image && (
                  <img
                    src={urlFor(community.image).url()}
                    alt={community.title}
                  />
                )}
              </div>
              <h3>{community.title}</h3>
              <p>{community.description}</p>
              <h4>Amenities</h4>
              <ul>
                {community.amenities.map((amenity, index) => (
                  <li key={index}>{amenity}</li>
                ))}
              </ul>
              <h4>Featured Listings</h4>
              {community.featuredListings.map((listing, index) => (
                <Link
                  to={"/listing/" + listing.slug.current}
                  key={index}
                  className="listing-link"
                >
                  <h5>{listing.title}</h5>
                </Link>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CommunityShowcase;
