import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import "./ContactForm.css";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [result, showResult] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fahjl7m",
        "template_vdhs3rp",
        e.target,
        "iFHA_k4_bc2DMXK_p"
      )
      .then(
        (result) => {
          console.log(result.text);
          showResult(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setName("");
    setEmail("");
  };

  return (
    <div className="contact-form-container">
      <div className="contact-style-bar" />

      <h1 className="contact-title">Contact Beatrice Price</h1>
      <p className="contact-description">
        Looking to buy or sell property in Salida, Buena Vista, or surrounding areas? Fill out the form below, and we'll get in touch!
      </p>
      <div className="contact-style-bar2" />

      <form className="contact-form" onSubmit={sendEmail}>
        <input type="hidden" name="contact_number" />
        <label> | Name | </label>
        <input
          type="text"
          name="user_name"
          required
          onChange={handleNameChange}
          placeholder="Name"
        />
        <div className="name-animations">
          {name.split("").map((letter, index) => (
            <motion.span
              key={`${letter}-${index}`}
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              {letter}
            </motion.span>
          ))}
        </div>
        <label> | Email | </label>
        <input
          type="email"
          name="user_email"
          required
          onChange={handleEmailChange}
          placeholder="Email"
        />
        <div className="email-animations">
          {email.split("").map((letter, index) => (
            <motion.span
              key={`${letter}-${index}`}
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              {letter}
            </motion.span>
          ))}
        </div>
        <label> | Message | </label>
        <textarea name="message" required placeholder="Message" />
        <input type="submit" value="Send" />
      </form>
      {result && <p>Thanks for reaching out!</p>}
    </div>
  );
};

export default ContactForm;
