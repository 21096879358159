import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Sidebar from "./Sidebar";
import "./NavBar.css";

const NavBar = () => {
  const { currentUser } = useAuth();
  const [menuToggle, setMenuToggle] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollUp =
        lastScrollPos > currentScrollPos || currentScrollPos === 0;
      setIsScrollingUp(isScrollUp);
      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollPos]);

  useEffect(() => {
    const shimmerElements = document.querySelectorAll(".shimmer");

    const interval = setInterval(() => {
      shimmerElements.forEach((el) => el.classList.add("shimmer"));
    }, 4000); // Add shimmer class every 4 seconds

    const timeout = setTimeout(() => {
      shimmerElements.forEach((el) => el.classList.remove("shimmer"));
    }, 1500); // Remove shimmer class after 1.5 seconds (animation duration)

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (menuToggle) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }

    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [menuToggle]);

  const navItems = [
    { name: "About", path: "/about" },
    { name: "Property Search", path: "/quick-search" },
    { name: "Free Market Analysis", path: "/free-market-analysis" },
    { name: "Mortgage Calculator", path: "/calculator" },
    { name: "Featured Listings", path: "/featured-listings" },
    { name: "Recently Sold", path: "/recently-sold" },
    { name: "Sell", path: "/sell" },
    { name: "Client Testimonies", path: "/stories" },
    { name: "Contact", path: "/contact" },
    { name: "Neighborhoods", path: "/neighborhoods" },
    // {
    //   name: currentUser ? "My Account" : "Register",
    //   path: currentUser ? "/account" : "/register",
    // },
  ];

  const isHomepage = location.pathname === "/";

  return (
    <nav className={`navbar ${isScrollingUp ? "" : "hidden"} ${isHomepage ? "" : "navbar-white"}`}>
      <div className="nav-header">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: isHomepage ? "white" : "black",
            fontSize: "1rem",
            marginLeft: "1rem",
          }}
        >
          BEATRICE PRICE
        </Link>
      </div>
      <div className="btn-container">
        {/* <Link to="/signup" className={`nav-btn register-btn ${isHomepage ? "shimmer" : ""}`} style={{ color: isHomepage ? "white" : "black" }}>
          Register
        </Link> */}
        <button
          className={`nav-btn menu-btn ${isHomepage ? "shimmer" : ""}`}
          onClick={() => setMenuToggle(!menuToggle)}
          style={{ color: isHomepage ? "white" : "black" }}
        >
          Menu
        </button>
      </div>
      <Sidebar items={navItems} toggle={menuToggle} setToggle={setMenuToggle} />
    </nav>
  );
};

export default NavBar;
