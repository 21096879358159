import { useState } from "react";
import emailjs from "emailjs-com";
import "./FreeMarketAnalysis.css";

const FreeMarketAnalysis = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    additionalInfo: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fahjl7m",
        "template_nkrzwne",
        e.target,
        "iFHA_k4_bc2DMXK_p"
      )
      .then(
        (result) => {
          setMessage("Email sent successfully!");
        },
        (error) => {
          setMessage("Error sending email. Please try again.");
        }
      );
    e.target.reset();
  };

  return (
    <div className="free-market-analysis">
      <h1>Free Market Analysis</h1>
      <p>
        Find out how much your home is worth with our free, no-obligation market
        analysis.
      </p>
      {message && <div className="alert">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={handleChange}
            value={formData.name}
            placeholder="Enter your name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={handleChange}
            value={formData.email}
            placeholder="Enter your email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            className="form-control"
            name="phone"
            onChange={handleChange}
            value={formData.phone}
            placeholder="Enter your phone number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Property Address</label>
          <input
            type="text"
            className="form-control"
            name="address"
            onChange={handleChange}
            value={formData.address}
            placeholder="Enter the property address"
          />
        </div>
        <div className="form-group">
          <label htmlFor="additionalInfo">Additional Information</label>
          <textarea
            className="form-control"
            name="additionalInfo"
            rows="3"
            onChange={handleChange}
            value={formData.additionalInfo}
            placeholder="Enter additional information"
          ></textarea>
        </div>
        <button type="submit" className="btn btn-danger">
          Submit
        </button>
      </form>
    </div>
  );
};

export default FreeMarketAnalysis;
