import { createClient } from "@sanity/client";

const client = createClient({
  projectId: "1bcwop30",
  dataset: "production",
  apiVersion: "2023-05-01", // use a date like '2023-01-29'
  useCdn: true,
});

export default client;
