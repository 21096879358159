import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { SiZillow } from 'react-icons/si';
import "./Footer.css";
import fclo from "../assets/fclo.png"; // Make sure the path is correct

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-header">
          <h4>Beatrice Price First Colorado Land</h4>
          <p className="contact-item">
            <Link to="#" onClick={(e) => { e.preventDefault(); window.location.href = 'tel:719-221-2647'; }} className="contact-link">
              <FaPhoneAlt /> 719-221-2647
            </Link>
          </p>
          <p className="contact-item">
            <Link to="#" onClick={(e) => { e.preventDefault(); window.location.href = 'mailto:BEATRICEPRICE@FIRSTCOLORADO.COM'; }} className="contact-link">
              <FaEnvelope /> BEATRICEPRICE@FIRSTCOLORADO.COM
            </Link>
          </p>
        </div>
        <div className="footer-section">
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/buyers">Buyers</Link></li>
            <li><Link to="/sellers">Sellers</Link></li>
            <li><Link to="/property-search">Property Search</Link></li>
            <li><Link to="/recently-sold">Recently Sold</Link></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
        </div>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/YourPage" target="_blank" rel="noopener noreferrer">
            <FaFacebookF className="social-icon" />
          </a>
          <a href="https://www.zillow.com/profile/YourProfile" target="_blank" rel="noopener noreferrer">
            <SiZillow className="social-icon" />
          </a>
          <a href="https://www.firstcolorado.com" target="_blank" rel="noopener noreferrer">
            <img src={fclo} alt="Company" className="company-logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
