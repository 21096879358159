// components/AgentCard.js
import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import "./AgentCard.css";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function AgentCard() {
  const [agentData, setAgentData] = useState(null);
  const navigate = useNavigate(); // Invoke useNavigate

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "agent"]{
          name,
          description,
          image,
        }`
      )
      .then((data) => setAgentData(data[0]))
      .catch(console.error);
  }, []);

  if (!agentData) return <div>Loading...</div>;

  const { name, description, image } = agentData;

  return (
    <div className="agent-card">
      <div className="agent-text">
        <h2>{name}</h2>
        <p>{description}</p>
        <button className="agent-button" onClick={() => navigate("/about")}>
          Learn More
        </button>{" "}
        {/* Use navigate function on button click */}
      </div>
      <div className="agent-image">
        <img src={urlFor(image).url()} alt={name} />
      </div>
    </div>
  );
}
