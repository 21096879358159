import React from "react";
import { Link } from "react-router-dom";
import "./FirstVisitPopup.css";

const FirstVisitPopup = ({ closePopup }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Welcome to Beatrice Price at First Colorado Land </h2>
        <p>Please let us know your interest:</p>
        <div className="options">
          <Link to="/quick-search" onClick={closePopup} className="btn">
            Buy
          </Link>
          <Link to="/sell" onClick={closePopup} className="btn">
            Sell
          </Link>
          <Link to="/" onClick={closePopup} className="btn">
            Just Browsing
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FirstVisitPopup;
