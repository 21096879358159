import React, { useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import sanityClient from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import { useParams } from "react-router-dom";
import "./FeaturedListingDetail.css";
import Skeleton from "./Skeleton";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faBath,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";

const MatterportViewer = lazy(() => import("./MatterportViewer"));

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function FeaturedListingDetail() {
  const [listingData, setListingData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "featuredListing" && slug.current == $slug]{
      title,
      description,
      images,
      address,
      price,
      bedroom,
      bathroom,
      sqft,
      video,
      matterportUrl,
      agent->{
        name,
        image,
        description,
        phone,
        email,
        dre,
        title
      }
    }`,
        { slug }
      )
      .then((data) => setListingData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!listingData) return <Skeleton title image text />;

  const {
    title,
    description,
    images,
    address,
    price,
    bedroom,
    bathroom,
    sqft,
    video,
    matterportUrl,
    agent,
  } = listingData;

  const videoUrl = video?.asset?.url;

  return (
    <div className="featured-listing-detail">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "RealEstateAgent",
            name: agent?.name,
          })}
        </script>
      </Helmet>

      {images && images.length > 0 && (
        <Carousel className="carousel-container" showThumbs={false}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={urlFor(image).url()}
                alt={`Slide ${index + 1} - ${title}`}
              />
            </div>
          ))}
        </Carousel>
      )}

      <div className="detail-section">
        <h1 className="detail-title">{title}</h1>
        <h2 className="detail-address">{address}</h2>
        <p className="detail-price">${new Intl.NumberFormat().format(price)}</p>
        <div className="icon-section">
          <div className="icon-container">
            <FontAwesomeIcon icon={faBed} className="icon" />
            <p>{bedroom} Bedrooms</p>
          </div>
          <div className="icon-container">
            <FontAwesomeIcon icon={faBath} className="icon" />
            <p>{bathroom} Bathrooms</p>
          </div>
          <div className="icon-container">
            <FontAwesomeIcon icon={faRulerCombined} className="icon" />
            <p>{sqft} Sq Ft</p>
          </div>
        </div>
        <p className="detail-desc">{description}</p>

        {videoUrl && (
          <video
            controls
            src={videoUrl}
            className="video-player"
            preload="none"
          >
            Your browser does not support the video tag.
          </video>
        )}

        {/* <Suspense fallback={<div>Loading 3D Viewer...</div>}>
          <MatterportViewer url={matterportUrl} />
        </Suspense> */}

        {agent && (
          <div className="agent-info">
            <img
              src={urlFor(agent.image).url()}
              alt={`Agent: ${agent.name}`}
              className="agent-image"
            />
            <h3>{agent.name}</h3>
            <p>{agent.title}</p>
            <p>{agent.phone}</p>
            <p>{agent.email}</p>
          </div>
        )}
      </div>
    </div>
  );
}
