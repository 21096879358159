import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PhotoGallery from "./PhotoGallery";
import "./ListingDetails.css";

const ListingDetails = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);

  useEffect(() => {
    fetch(`http://127.0.0.1:3000/properties/${id}`)
      .then((response) => response.json())
      .then((data) => setProperty(data));
  }, [id]);

  if (!property) {
    return <div>Loading...</div>;
  }

  const { address, price, bedrooms, bathrooms, sqft, lot_size, img_url } =
    property;

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-12">
          <h2>{address}</h2>
          <h3 className="text-primary">${price.toLocaleString()}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <PhotoGallery images={img_url} />
        </div>
        <div className="col-md-4">
          <p>
            {bedrooms} Beds | {bathrooms} Baths | {sqft} Sq. Ft. | {lot_size}{" "}
            Sq. Ft. Lot
          </p>
          <button className="btn btn-primary">Contact Agent</button>
          <div className="mt-3">
            <h5>Property Details</h5>
            <table className="table">
              <tbody>
                <tr>
                  <th>Address</th>
                  <td>{address}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>${price.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>Bedrooms</th>
                  <td>{bedrooms}</td>
                </tr>
                <tr>
                  <th>Bathrooms</th>
                  <td>{bathrooms}</td>
                </tr>
                <tr>
                  <th>Square Footage</th>
                  <td>{sqft}</td>
                </tr>
                <tr>
                  <th>Lot Size</th>
                  <td>{lot_size} Sq. Ft.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <br></br>
            <div className="section-divider"></div>
            <div className="agent-info">
              <img
                src="https://via.placeholder.com/150"
                alt="Agent Beatrice Price"
                className="agent-image"
              />
              <h4>Beatrice Price</h4>
              <p className="agent-title">Realtor | Local Property Specialist</p>
              <p className="agent-contact">
                <strong>Phone:</strong> 7192212647
              </p>
              <p className="agent-contact">
                <strong>Email:</strong> beatrice@firstcoloradolandoffice.com
              </p>
              <p className="agent-contact">
                <strong>DRE #:</strong> 01234567
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetails;
