import React from "react";
import IFrameComponent from "./IFrameComponent";
import QuickSearch from "./QuickSearch";
import { useNavigate } from "react-router-dom";
import TrendsComponent from "./TrendsComponent";

function AdvancedSearch() {
  const navigate = useNavigate();
  return (
    <div>
      <QuickSearch />
      {/* <TrendsComponent /> */}
    </div>
  );
}

export default AdvancedSearch;
