import React from "react";
import { motion } from "framer-motion";
import "./Skeleton.css";

const Skeleton = ({ title = true, image = true, text = true }) => {
  return (
    <div className="skeleton-wrapper">
      {title && <div className="skeleton-title"></div>}
      {image && <div className="skeleton-image"></div>}
      {text && <div className="skeleton-text"></div>}
      <LoadingIndicator />
    </div>
  );
};

const LoadingIndicator = () => {
  return (
    <motion.div
      className="loading-indicator"
      animate={{ rotate: 360 }}
      transition={{ ease: "linear", duration: 1, loop: Infinity }}
    />
  );
};

export default Skeleton;
