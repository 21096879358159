import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function SoldListings() {
  const [soldListingsData, setSoldListingsData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "featuredListing" && status == "sold"]{
          title,
          images,
          price,
        }`
      )
      .then((data) => setSoldListingsData(data))
      .catch(console.error);
  }, []);

  if (!soldListingsData) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{ maxWidth: "100vw", overflow: "hidden", marginBottom: "20px" }}
    >
      <h2
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Recently Sold
      </h2>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "20px",
          padding: "0 20px",
        }}
      >
        {soldListingsData.map((listing, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={urlFor(listing.images[0]).url()}
              alt={listing.title}
              style={{
                width: "100%",
                height: "200px",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "red",
                padding: "5px 10px",
                borderRadius: "5px",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              SOLD
            </div>
            <div
              style={{
                marginTop: "8px",
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "center",
                color: "green",
                padding: "10px 0",
              }}
            >
              {listing.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
