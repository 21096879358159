import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../sanityClient";
import "./Hero.css";

const Hero = () => {
  const [heroContent, setHeroContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "hero"] | order(order asc) {
          title,
          subtitle,
          videoFile{
            asset->{
              url
            }
          },
          buttonText,
          buttonLink
        }`
      )
      .then((data) => setHeroContent(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        heroContent.length ? (prevIndex + 1) % heroContent.length : 0
      );
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentIndex, heroContent]);

  if (!heroContent.length) return null;

  const currentContent = heroContent[currentIndex];

  return (
    <div className="hero">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="hero-video"
        src={currentContent.videoFile.asset.url}
      >
        Your browser does not support the video tag.
      </video>
      <div className="hero-overlay" />
      <div className="hero-content">
        <h2 className="hero-subtitle">{currentContent.subtitle}</h2>
        <div className="style-bar" />
        <h1 className="hero-title">{currentContent.title}</h1>
        <Link to={currentContent.buttonLink} className="hero-button">
          {currentContent.buttonText}
        </Link>
      </div>
    </div>
  );
};

export default Hero;
