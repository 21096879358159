import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TrendsComponent.css";
import IFrameComponent from "./IFrameComponent";
const TrendsComponent = () => {
  const [realEstateNews, setRealEstateNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        // const denverResponse = await axios.get(
        //   "http://127.0.0.1:3000/denver_real_estate_news"
        // );
        const coloradoResponse = await axios.get(
          "http://127.0.0.1:3000/colorado_realtor_news"
        );

        const allNews = [
          // ...denverResponse.data,
          ...coloradoResponse.data,
        ];
        allNews.sort((a, b) => new Date(b.time) - new Date(a.time));

        setRealEstateNews(allNews);
      } catch (error) {
        console.error("Error fetching real estate news:", error);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="trends-container">
      <h2 className="trends-title">Real Estate News</h2>
      <div className="trends">
        <IFrameComponent
          src="https://car-co.stats.showingtime.com/reports"
          title="Reports"
          className="frame"
        />
        {realEstateNews.map((newsItem, index) => (
          <div key={index} className="newsItem">
            <h3>
              <a href={newsItem.link}>{newsItem.title}</a>
            </h3>
            <img src={newsItem.image} alt={newsItem.title} />
            <p>{newsItem.author}</p>
            <p>{newsItem.time}</p>
            <p>{newsItem.excerpt}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendsComponent;
