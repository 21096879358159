import React, { useState } from "react";

const PhotoGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  if (!images || images.length === 0) {
    return <div>No images available</div>;
  }

  return (
    <div className="photo-gallery">
      <img
        src={images[currentImageIndex]}
        alt="Property"
        className="img-fluid"
      />
      <button className="btn btn-primary prev-btn" onClick={handlePrev}>
        &lt;
      </button>
      <button className="btn btn-primary next-btn" onClick={handleNext}>
        &gt;
      </button>
    </div>
  );
};

export default PhotoGallery;
