import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import LikesProvider from "./context/LikesProvider";
import NavBar from "./components/NavBar";
import QuickSearch from "./components/QuickSearch";
import ListingsCard from "./components/ListingsCard";
import ListingDetails from "./components/ListingDetails";
import Login from "./components/Login";
import Signup from "./components/Signup";
import MyLikes from "./components/MyLikes";
import Logout from "./components/Logout";
import Profile from "./components/Profile";
import Footer from "./components/Footer";
import Properties from "./components/Properties";

import FreeMarketAnalysis from "./components/FreeMarketAnalysis";
import Home from "./Home";
import client from "./sanityClient";

import FeaturedListingDetail from "./components/FeaturedListingDetail";
import FirstVisitPopup from "./components/FirstVisitPopop";
import "./App.css";
import axios from "axios";
import FeaturedListings from "./components/FeaturedListings";
import About from "./components/About";
import MortgageCalculator from "./components/MortgageCalculator";
import Search from "./components/SearchBar";
import SearchBar from "./components/SearchBar";
import CommunityShowcase from "./components/CommunityShowcase";
import WaterRightTransactions from "./components/WaterRightTransactions";
import ContactForm from "./components/ContactForm";
// import GoogleTrends from "./components/GoogleTrends";
import TrendsComponent from "./components/TrendsComponent";
import IFrameComponent from "./components/IFrameComponent";
import NeighborhoodGuides from "./components/NeighborhoodGuides";
import Neighborhood from "./components/Neighborhood"; // Assuming that this is the import for your Neighborhood component
import AdvancedSearch from "./components/AdvancedSearch";
import CommunityPage from "./components/CommunityPage";
import SoldListings from "./components/SoldListings";
import Testimonials from "./components/Testimonials";
import Sell from "./components/Sell";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App() {
  const [properties, setProperties] = useState([]);

  const [likedProperties, setLikedProperties] = useState(() => {
    const storedLikes = localStorage.getItem("likedProperties");
    return storedLikes ? JSON.parse(storedLikes) : [];
  });

  const [search, setSearch] = useState({
    location: "",
    propertyType: "",
    minPrice: "",
    maxPrice: "",
    bedrooms: "",
    bathrooms: "",
    squareFeet: "",
    lotSize: "",
  });

  const [popupVisible, setPopupVisible] = useState(() => {
    const firstVisit = localStorage.getItem("firstVisit");
    return firstVisit === null || firstVisit === "true";
  });

  const closePopup = () => {
    setPopupVisible(false);
    localStorage.setItem("firstVisit", "false");
  };



  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
  };

  const filteredProperties = properties.filter((property) => {
    return (
      (search.location === "" ||
        property.address
          .toLowerCase()
          .includes(search.location.toLowerCase())) &&
      (search.propertyType === "" ||
        property.property_type === search.propertyType) &&
      (search.minPrice === "" ||
        property.price >= parseFloat(search.minPrice)) &&
      (search.maxPrice === "" ||
        property.price <= parseFloat(search.maxPrice)) &&
      (search.bedrooms === "" ||
        property.bedrooms >= parseInt(search.bedrooms, 10)) &&
      (
        search.bathrooms === "" ||
        property.bathrooms >= parseInt(search.bathrooms, 10)
      )(
        search.squareFeet === "" ||
          property.square_feet >= parseInt(search.squareFeet, 10)
      ) &&
      (search.lotSize === "" ||
        property.lot_size >= parseInt(search.lotSize, 10))
    );
  });

  const handleLike = (propertyId) => {
    if (!likedProperties.includes(propertyId)) {
      const newLikedProperties = [...likedProperties, propertyId];
      setLikedProperties(newLikedProperties);
      localStorage.setItem(
        "likedProperties",
        JSON.stringify(newLikedProperties)
      );
    }
  };

  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        {/* <GoogleTrends /> */}
        <LikesProvider>
          {popupVisible && <FirstVisitPopup closePopup={closePopup} />}
          <NavBar />

          <Routes>
            <Route
              path="/"
              element={
                <Home onSearch={handleSearch} properties={filteredProperties} />
              }
            />
            <Route path="/property/:id" element={<ListingDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/featured-listings" element={<FeaturedListings />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/frame" element={<IFrameComponent />} />
            <Route path="/sell" element={<Sell />} />

            <Route
              path="/neighborhood/:neighborhoodSlug"
              element={<Neighborhood />}
            />

            <Route
              path="/neighborhood-guide"
              element={<NeighborhoodGuides />}
            />
            <Route path="/commy" element={<CommunityPage />} />

            <Route path="/calculator" element={<MortgageCalculator />} />
            <Route path="/quick-search" element={<IFrameComponent />} />
            <Route path="/advanced-search" element={<AdvancedSearch />} />

            <Route path="/com" element={<CommunityShowcase />} />
            <Route path="/trends" element={<TrendsComponent />} />
            <Route path="/stories" element={<Testimonials />} />


            <Route
              path="/properties"
              element={<Properties properties={properties} />}
            />
            <Route
              path="/water-right-transactions"
              element={<WaterRightTransactions />}
            />
            <Route
              path="/free-market-analysis"
              element={<FreeMarketAnalysis />}
            />
            <Route
              path="/featured-listing/:slug"
              element={<FeaturedListingDetail />}
            />
            <Route path="/recently-sold" element={<SoldListings />} />
          </Routes>

          <Footer />
        </LikesProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
