// src/components/MyLikes.js

import React, { useContext } from "react";
import ListingsCard from "./ListingsCard";
import LikesContext from "../context/LikeContext";

const MyLikes = () => {
  const { likes } = useContext(LikesContext);

  return (
    <div>
      <h2>My Likes</h2>
      <div className="row">
        {likes.map((property) => (
          <div key={property.id} className="col-md-4">
            <ListingsCard property={property} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyLikes;
