import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import "./Testimonials.css";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "testimonial"]{quote, name}`)
      .then((data) => setTestimonials(data))
      .catch(console.error);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : testimonials.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < testimonials.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <section className="testimonials">
      <h2 className="testimonials-title">What My Clients Say</h2>
      <div className="testimonials-carousel">
        {testimonials.length > 0 ? (
          <div className="testimonial-slide">
            <blockquote className="testimonial-quote">
              {testimonials[currentIndex].quote}
            </blockquote>
            <p className="testimonial-name">- {testimonials[currentIndex].name}</p>
            <div className="testimonial-controls">
              <button className="carousel-button left" onClick={handlePrev}>
                &#8592;
              </button>
              <button className="carousel-button right" onClick={handleNext}>
                &#8594;
              </button>
            </div>
          </div>
        ) : (
          <p>Loading testimonials...</p>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
